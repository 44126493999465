import React from "react";
import {Box} from "@chakra-ui/react";

export type SafeViewProps = {
  children: React.ReactNode;
};

const SafeView = (props: SafeViewProps) => {
  const {children} = props;
  return (
    <Box
      px={{
        base: "1rem",
        sm: "2rem",
        lg: "3rem",
        xl: "5rem",
        "2xl": "calc(calc(100vw - 1600px) / 2)",
        "3xl": "calc(calc(100vw - 1800px) / 2)",
      }}
    >
      {children}
    </Box>
  );
};

export default SafeView;
